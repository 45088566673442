.violet-stripe {
  background-color: var(--violet) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3vw;
  padding-right: 3vw;
}

.violet-stripe-text {
  font-family: "Open Sans", Arial, Tahoma, sans-serif;
  margin-top: 2vw;
  margin-bottom: 2vw;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 0px;
  color: var(--white);
  text-align: center;
}
