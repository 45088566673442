.navbar-container {
  display: flex;
  justify-content: center;
}
.navbar-expand-lg {
  padding: 0px;
}
.navbar-logo {
  height: 90px;
}
.nav-bar-button-option {
  margin-top: 25px;
  padding: 10px 20px !important;
  height: 50px;
}
.nav-bar-button-option span {
  color: var(--text-light-grey);
}
.navbar-nav li {
  margin: 22px 0px;
}
.navbar-nav :last-child button {
  border-right: unset;
}
.nav-bar-selected {
  border-bottom: var(--violet) 5px solid;
}
.nav-bar-selected button span {
  color: var(--violet) !important;
}
.nav-item {
  height: 100px;
  margin: 0px !important;
}
.nav-item:hover {
  border-bottom: var(--violet) 5px solid;
  transition: 0.2s;
}
.container-fluid img {
  margin-right: 3vw;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(102,47,148)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  color: var(--violet);
}
.navbar-toggler {
  border: unset;
}
.navbar-toggler:focus {
  box-shadow: unset;
}

.nav-bar-border-right {
  border-right: solid 1px var(--super-light-grey);
}
@media only screen and (max-width: 991px) {
  .navbar-container-fluid {
    width: 100vw;
  }
  .navbar-logo {
    margin-left: 3vw;
    height: 60px;
  }
  .nav-bar-selected {
    color: var(--violet) !important;
    border-bottom: unset;
    border-left: var(--violet) 5px solid;
    background-color: var(--super-light-grey);
  }
  .nav-item {
    height: unset;
  }
  .nav-item:hover {
    transition: 0.2s;
    color: var(--violet) !important;
    border-bottom: unset;
    border-left: var(--violet) 5px solid;
  }
  .nav-bar-button-option {
    height: unset;
    margin-top: unset;
  }
  .nav-bar-border-right {
    border-right: unset;
  }
}
