:root {
  --violet: rgb(102, 47, 148);
  --white: rgb(250, 252, 255);
  --beige: rgb(253, 246, 228);
  --light-grey: rgb(204, 204, 204);
  --super-light-grey: rgb(244, 244, 244);
  --dark-grey: rgb(83, 83, 83);
  --text-grey: rgb(120, 120, 120);
  --text-light-grey: rgb(147, 147, 147);
  --linkedin: rgb(0, 115, 177);
  --link-text: rgb(89, 176, 226);
  --whatsapp: rgb(70, 194, 86);
}

* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: "Open Sans", Arial, Tahoma, sans-serif;
  color: var(--dark-grey);
}

@media only screen and (max-width: 481px) {
  html {
    font-size: 80%;
  }
}

@media only screen and (max-width: 320px) {
  html {
    font-size: 60%;
  }
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
}

p {
  font-size: 0.9rem;
  margin-bottom: 0;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.5rem;
}

.container {
  margin-top: 3vw;
  margin-bottom: 3vw;
}

.violet-letter {
  color: var(--violet);
}

.light-grey-letter {
  color: var(--text-light-grey);
}

.thin-letter {
  font-weight: 300;
}

.roboto {
  font-family: "Roboto", Arial, Tahoma, sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
}
