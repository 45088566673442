.footer {
  align-items: center;
  background-color: var(--dark-grey);
  color: var(--light-grey);
  display: flex;
  font-family: "Roboto", Arial, Tahoma, sans-serif;
  height: 56px;
  justify-content: center;
  text-align: center;
}
