.attention-card {
  display: flex;
  gap: 3vw;
  margin-bottom: 2vw;
}
.attention-card-photo {
  max-width: 33vw;
  height: auto;
}
.attention-card-text {
  background-color: var(--super-light-grey);
  border-left: solid 3px var(--violet);
  padding: 40px 15px 40px 25px;
}
.attention-card-title {
  margin-left: 20px;
  color: var(--violet);
}
.attention-card-body {
  color: var(--text-grey);
}

@media only screen and (max-width: 991px) {
  .attention-card-photo {
    max-width: 100%;
    height: unset;
  }
  .attention-card {
    flex-direction: column;
    align-items: center;
  }
}
